<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>程序运行</span>
            <el-divider direction="vertical"></el-divider> 
            <el-button @click="runProgram" type="primary" size="small" icon="el-icon-caret-right" :loading="runButtonLoading"></el-button>
        </div>
        <div class="run-content">
            <div class="run-section">
                <h3>入参</h3>
                <el-input type="textarea" :rows="10" v-model="input" class="run-input"></el-input>
            </div>
            <div class="run-section">
                <h3>过程</h3>
                <el-input type="textarea" :rows="10" :value="process" class="run-process" readonly></el-input>
            </div>
            <div class="run-section">
                <h3>出参</h3>
                <el-input type="textarea" :rows="10" :value="output" class="run-output" readonly></el-input>
            </div>
        </div>
    </el-card>
</template>
  
<script>
import axios from 'axios'

export default {
    props: ['programIndex', 'promptText', 'variables', 'functions', 'currentModel'],
    data() {
        return {
            input: this.getStoredInput(),
            process: this.getStoredProcess(),
            output: this.getStoredOutput(),
            runButtonLoading: false,
        }
    },
    mounted() {
        this.input = this.getStoredInput();
        this.process = this.getStoredProcess();
        this.output = this.getStoredOutput();
    },
    watch: {
        programIndex: {
            immediate: true,
            handler() {
                this.input = this.getStoredInput();
                this.process = this.getStoredProcess();
                this.output = this.getStoredOutput();
            }
        }
    },

    methods: {
        getStoredInput() {
            const storedInput = localStorage.getItem(`input_${this.programIndex}`);
            return storedInput !== null ? storedInput : "";
        },
        getStoredProcess() {
            const storedProcess = localStorage.getItem(`process_${this.programIndex}`);
            return storedProcess !== null ? storedProcess : "";
        },
        getStoredOutput() {
            const storedOutput = localStorage.getItem(`output_${this.programIndex}`);
            return storedOutput !== null ? storedOutput : "";
        },
        async runProgram() {
            this.runButtonLoading = true; // 开始运行时，设置按钮为 loading 状态
            this.process = '运行中...'; // 开始运行时，设置输出为 "运行中"
            this.output = '运行中...'; // 开始运行时，设置输出为 "运行中"
            localStorage.setItem(`input_${this.programIndex}`, this.input);

            try {
                const inputJson = JSON.parse(this.input); // 将输入转换为 JSON
                const checkedVariableNames = this.variables
                    .filter(variable => variable.isChecked)  // 过滤出 isChecked 为 true 的变量
                    .map(variable => variable.name);  // 提取这些变量的 name
                const postData = {
                    template: this.promptText || "",
                    variable: {
                        input: inputJson,
                    },
                    function: (this.functions ? this.functions.reduce((acc, cur) => {
                        acc[cur.name] = cur.code;
                        return acc;
                        }, {}) : {}) || {},
                    model: this.currentModel,
                    output_variable: checkedVariableNames
                };
                const response = await axios.post(this.currentModel.guidance, postData)
                this.process = response.data.data.text
                this.output = JSON.stringify(response.data.data.variables, null, 2) // 使用 JSON.stringify
            } catch (error) {
                console.error(error)
                this.process = `平台前端运行错误: ${error}`;
                this.output = {};
            } finally {
                this.runButtonLoading = false; // 运行结束后，设置按钮为非 loading 状态
                localStorage.setItem(`process_${this.programIndex}`, this.process);
                localStorage.setItem(`output_${this.programIndex}`, this.output);
            }
        }
    }
}
</script>
  
<style scoped>

.box-card {
    height: 400px;
}

.run-content {
    display: flex;
    justify-content: space-between;
}

.run-section {
    width: 30%;
}


.run-input,
.run-process,
.run-output {
    width: 100%;
    height: 100px;
    margin-bottom: 5px;
}
</style>