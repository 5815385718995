<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span class="eidot-title">编辑器</span>
    </div>
    <el-input type="textarea" :rows="13" placeholder="请输入你的提示" v-model="promptText" @input="updatePromptText"/>
  </el-card>
</template>

<script>
export default {
  props: {
    programIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      promptText: this.getStoredProgramText(),
      promptVariables: this.getStoredProgramVariables(),
    };
  },
  mounted() {
    this.promptText = this.getStoredProgramText();
    this.promptVariables = this.getStoredProgramVariables();
  },
  watch: {
    programIndex: {
      immediate: true,
      handler() {
        this.promptText = this.getStoredProgramText();
        this.promptVariables = this.getStoredProgramVariables();
      }
    },
    promptText: {
      handler() {
        this.updatePromptText();
        this.updatePromptVariables();
      }
    }
  },
  methods: {
    getStoredProgramText() {
      const storedPromptText = localStorage.getItem(`promptText_${this.programIndex}`);
      return storedPromptText !== null ? storedPromptText : "";
    },
    getStoredProgramVariables() {
        const storedPromptVariables = localStorage.getItem(`promptVariables_${this.programIndex}`);
        return storedPromptVariables !== null ? JSON.parse(storedPromptVariables) : [];
    },
    getVariablesFromPrompt() {
      const re = /{{(?:gen|#select|set)\s+["'](.*?)["']/g;
        let match;
        let promptVariables = [];
        while ((match = re.exec(this.promptText)) !== null) {
            let variableName = match[1].trim();
            if (!promptVariables.find(v => v.name === variableName)) {
                promptVariables.push({ name: variableName });
            }
        }
        return promptVariables || [];
    },
    updatePromptText() {
      localStorage.setItem(`promptText_${this.programIndex}`, this.promptText);
      this.$emit('updatePromptText', this.promptText);
    },
    updatePromptVariables() {
        this.promptVariables = this.getVariablesFromPrompt();
        localStorage.setItem(`promptVariables_${this.programIndex}`, JSON.stringify(this.promptVariables));
        this.$emit('updatePromptVariables', this.promptVariables);
    },
  }
};
</script>

<style scoped>
.box-card {
  height: 400px;
  margin: 0px;
}

.run-title {
  text-align: center;
  margin-bottom: 5px;
}
</style>