<template>
  <el-container>
    <el-aside width="200px">
      <el-menu class="el-menu-vertical-demo" :default-active="selectedProgramIndex.toString()" @select="selectProgram">
        <el-menu-item v-for="(program, index) in programs" :key="index" :index="index.toString()">
          Program {{ index + 1 }}
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-container class="container-top">
        <el-main>
          <PromptEditor 
          :programIndex="selectedProgramIndex"
          :promptText="programs[selectedProgramIndex].promptText"
          :promptVariables="programs[selectedProgramIndex].promptVariables"
          @updatePromptText="updatePromptText(selectedProgramIndex, $event)"
          @updatePromptVariables="updatePromptVariables(selectedProgramIndex, $event)"
          />
        </el-main>
        <el-main>
          <ConfigItem 
            :programIndex="selectedProgramIndex"
            :promptVariables="programs[selectedProgramIndex].promptVariables"
            :variables="programs[selectedProgramIndex].variables"
            :functions="programs[selectedProgramIndex].functions"
            :currentModel="programs[selectedProgramIndex].currentModel"
            @updateVariables="updateVariables(selectedProgramIndex, $event)"
            @saveFunction="saveFunction(selectedProgramIndex, $event)"
            @updateCurrentModel="updateCurrentModel(selectedProgramIndex, $event)"
          />
        </el-main>
      </el-container> 
      <el-footer> 
        <ProgramRun 
          :programIndex="selectedProgramIndex"
          :promptText="programs[selectedProgramIndex].promptText"
          :variables="programs[selectedProgramIndex].variables"
          :functions="programs[selectedProgramIndex].functions"
          :currentModel="programs[selectedProgramIndex].currentModel"
        />
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import PromptEditor from './components/PromptEditor.vue';
import ConfigItem from './components/ConfigItem.vue';
import ProgramRun from './components/ProgramRun.vue';

export default {
  components: {
    PromptEditor,
    ConfigItem,
    ProgramRun,
  },
  data() {
    let programs = localStorage.getItem('programs');

    return {
      selectedProgramIndex: 0,
      programs: programs ? JSON.parse(programs) :
        [
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} },
          { promptText: "", promptVariables: [], functions: [], currentModel: {} }
        ]
    };
  },
  methods: {
    selectProgram(index) {
      this.selectedProgramIndex = Number(index);
    },
    updatePromptText(index, value) {
      this.programs[index].promptText = value;
      this.updateProgramsInLocalStorage();
    },
    updatePromptVariables(index, value) {
      this.programs[index].promptVariables = value;
      this.updateProgramsInLocalStorage();
    },
    updateVariables(index, value) {
        this.programs[index].variables = value;
        this.updateProgramsInLocalStorage();
    },
    saveFunction(index, value) {
      this.programs[index].functions = value;
      this.updateProgramsInLocalStorage();
    },
    updateCurrentModel(index, value) {
      this.programs[index].currentModel = value;
      this.updateProgramsInLocalStorage();
    },
    updateProgramsInLocalStorage() {
      localStorage.setItem('programs', JSON.stringify(this.programs));
    }
  }
};
</script>

