<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span>配置项</span>
        </div>
        <el-col class="config-section">
            <div class="section-header">
            <span>变量</span>
            </div>
            <ul class="custom-list">
            <li v-for="(variable, index) in variables" :key="index">
                <el-row>
                <el-col :span="18">
                    <span>{{ variable.name }}</span>
                </el-col>
                <el-col :span="6">
                    <el-checkbox v-model="variable.isChecked" @change="updateVariable(variable, index)">出参</el-checkbox>
                </el-col>
                </el-row>
            </li>
            </ul>
        </el-col>

        <el-col class="config-section">
            <div class="section-header">
            <span>函数</span>
            <el-divider direction="vertical"></el-divider> 
            <el-button @click="createFunction" type="primary" size="small" icon="el-icon-plus"></el-button>
            </div>
            <ul class="custom-list">
            <li v-for="(func, index) in functions" :key="index">
                <el-row>
                <el-col :span="18">{{ func.name }}</el-col>
                <el-col :span="6">
                    <el-button @click="editFunction(index)" type="text" size="small">编辑</el-button>
                    <el-button @click="deleteFunction(index)" type="text" size="small">删除</el-button>
                </el-col>
                </el-row>
            </li>
            </ul>
        </el-col>

        <el-col class="config-section">
            <div class="section-header">
                <span>模型</span>
                <el-divider direction="vertical"></el-divider> 
                <el-button @click="createModel" type="primary" size="small" icon="el-icon-plus"></el-button>
            </div>
            <el-select v-model="currentModelName" placeholder="请选择模型" @change="updateCurrentModel">
                <el-option v-for="(model, index) in models" :key="index" :label="model.name" :value="model.name"></el-option>
            </el-select>
            <el-button @click="editModel(currentModel.name)" type="text" size="small">编辑</el-button>
            <el-button @click="deleteModel(currentModel.name)" type="text" size="small">删除</el-button>
        </el-col>

        <el-dialog :visible.sync="showFunctionModal" title="编辑函数">
            <el-form :model="currentFunction" inline-message class="inline-form">
                <el-form-item label="函数名">
                    <el-input v-model="currentFunction.name" />
                </el-form-item>

                <el-form-item label="描述">
                    <el-input v-model="currentFunction.description" />
                </el-form-item>

                <el-form-item label="代码" class="full-width">
                    <codemirror ref="codemirror" v-model="currentFunction.code" :options="cmOptions"
                        class="codemirror-editor"></codemirror>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="saveFunction">保存</el-button>
                <el-button @click="runFunction(currentFunction.code)">运行</el-button>
                <el-button @click="closeFunction">关闭</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="showModelModal" title="编辑模型">
            <el-form :model="editingModel" inline-message class="inline-form">
                <el-form-item label="模型名称">
                    <el-input v-model="editingModel.name" />
                </el-form-item>

                <el-form-item label="模型类型">
                    <el-select v-model="editingModel.type" placeholder="请选择模型类型">
                        <el-option label="OpenAI" value="openai"></el-option>
                        <el-option label="自定义接口" value="custom"></el-option>
                    </el-select>
                    <el-input v-if="editingModel.type === 'custom'" v-model="editingModel.api" placeholder="请输入自定义模型接口" />
                    <el-input v-if="editingModel.type === 'openai'" v-model="editingModel.api" placeholder="请输入OpenAI Api Key" />
                </el-form-item>

                <el-form-item label="模型描述">
                    <el-input v-model="editingModel.description" />
                </el-form-item>

                <el-form-item label="guidance server">
                    <el-input v-model="editingModel.guidance" />
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="saveModel">保存</el-button>
                <el-button @click="closeModel">关闭</el-button>
            </span>
        </el-dialog>

    </el-card>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// Import Python mode
import 'codemirror/mode/python/python'

export default {
    props: ['programIndex', 'promptVariables'],
    data() {
        return {
            variables: this.getStoredVariables(),
            
            functions: this.getStoredFunctions(),
            currentFunction: {
                name: '',
                description: '',
                code: ''
            },
            // Set mode to 'python' to enable Python syntax highlighting
            cmOptions: {
                mode: 'python',
                lineNumbers: true,
                matchBrackets: true
            },

            models: this.getStoredModels(),
            currentModel: this.getStoredCurrentModel(),
            currentModelName: this.getStoredCurrentModelName(),
            editingModel: {
                name: '',
                type: '',
                description: '',
                api: '',
                guidance: ''
            },

            showFunctionModal: false,

            showModelModal: false,
            editingModelIndex: null,
            
        };
    },
    mounted() {
        this.variables = this.getStoredVariables();

        this.functions = this.getStoredFunctions();

        this.models = this.getStoredModels();
        this.currentModel = this.getStoredCurrentModel();
        this.currentModelName = this.getStoredCurrentModelName();
    },
    watch: {
        programIndex: {
            immediate: true,
            handler() {
                this.variables = this.getStoredVariables();

                this.functions = this.getStoredFunctions();

                this.models = this.getStoredModels();
                this.currentModel = this.getStoredCurrentModel();
                this.currentModelName = this.getStoredCurrentModelName();
            }
        },
        showFunctionModal: function (newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.codemirror.refresh();
                });
            }
        },
        showModelModal: function (newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.codemirror.refresh();
                });
            }
        },
        // 监听 promptVariables 的变化
        promptVariables: {
            deep: true, // 因为 promptVariables 是一个对象数组，所以我们需要深度监听
            immediate: true, // 立即在组件初始化时执行处理函数
            handler(newValue) {
                if (!newValue) {
                    return;
                }
                // Find added variables, but exclude 'input'
                let addedVariables = newValue.filter(v => v.name !== 'input' && !this.variables.find(variable => variable.name === v.name));
                // Add editable and isChecked properties to added variables
                addedVariables = addedVariables.map(v => ({ ...v, editable: true, isChecked: true }));

                // Find removed variables, but exclude 'input'
                let removedVariables = this.variables.filter(v => v.name !== 'input' && !newValue.find(variable => variable.name === v.name));

                // Update variables, but keep 'input' at the front
                this.variables = [{ name: 'input', editable: false, isChecked: false }].concat(
                    this.variables.concat(addedVariables).filter(v => !removedVariables.includes(v) && v.name !== 'input')
                );

                // Store new variables to localStorage
                localStorage.setItem(`variables_${this.programIndex}`, JSON.stringify(this.variables));

                // Emit 'updateVariables' event
                this.$emit('updateVariables', this.variables);
            }  
        },
    },
    components: {
        codemirror
    },
    methods: {
        getStoredVariables() {
            let variables = localStorage.getItem(`variables_${this.programIndex}`);
            variables = variables !== null ? JSON.parse(variables) : [];

            // Check if variables includes 'input'
            if (!variables.find(v => v.name === 'input')) {
                // If not, add 'input' to the front of variables
                variables.unshift({ name: 'input', editable: false, isChecked: false });
                localStorage.setItem(`variables_${this.programIndex}`, JSON.stringify(variables));
            }

            return variables;
        },
        updateVariable(variable, index) {
            this.variables[index] = variable;
            localStorage.setItem(`variables_${this.programIndex}`, JSON.stringify(this.variables));
            this.$emit('updateVariables', this.variables);
        },

        getStoredFunctions() {
            const functions = localStorage.getItem(`functions_${this.programIndex}`);
            return functions !== null ? JSON.parse(functions) : [];
        },
        createFunction() {
            console.log('createFunction was called');
            this.currentFunction = {
                name: '',
                description: '',
                code: ''
            };
            this.showFunctionModal = true;
        },
        editFunction(index) {
            this.currentFunctionIndex = index;
            this.currentFunction = { ...this.functions[index] };
            this.showFunctionModal = true;
        },
        deleteFunction(index) {
            this.functions.splice(index, 1);
        },
        saveFunction() {
            if (this.currentFunctionIndex !== null) {
                this.functions[this.currentFunctionIndex] = this.currentFunction;
            } else {
                this.functions.push(this.currentFunction);
            }
            localStorage.setItem(`functions_${this.programIndex}`, JSON.stringify(this.functions));
            this.$emit('saveFunction', this.functions);
            this.closeFunction();
        },
        runFunction(code) {
            console.log(code);
            // This is where you should execute the function's code. For security and complexity reasons, I'm leaving it blank here.
        },
        closeFunction() {
            this.showFunctionModal = false;
            this.currentFunctionIndex = null;
        },
        formatTooltip(val) {
            return Number((val / 100).toFixed(2));
        },

        getStoredModels() {
            const models = localStorage.getItem('models');
            return models !== null ? JSON.parse(models) : [];
        },
        getStoredCurrentModel() {
            const currentModel = localStorage.getItem(`currentModel_${this.programIndex}`);
            return currentModel !== null ? JSON.parse(currentModel) : {};
        },
        getStoredCurrentModelName() {
            const currentModelName = localStorage.getItem(`currentModelName_${this.programIndex}`);
            return currentModelName !== null ? currentModelName : "";
        },
        createModel() {
            this.editingModel = {
                name: '',
                type: '',
                description: '',
                api: '',
                guidance: ''
            };
            this.editingModelIndex = this.models.length; // Set index to new position
            this.showModelModal = true;
        },
        findModelIndex(modelName) {
            return this.models.findIndex(m => m.name === modelName);
        },
        editModel(modelName) {
            const index = this.findModelIndex(modelName);
            if (index !== -1) {
                this.editingModelIndex = index;
                this.editingModel = { ...this.models[index] };
                this.showModelModal = true;
            }
        },
        deleteModel(modelName) {
            const index = this.findModelIndex(modelName);
            if (index !== -1) {
                if (this.models[index].name === this.currentModel) {
                    this.currentModel = '';
                    localStorage.setItem(`currentModel_${this.programIndex}`, this.currentModel);
                    this.$emit('updateCurrentModel', this.currentModel);
                }
                this.models.splice(index, 1);
                this.saveModels();
            }
        },
        updateCurrentModel() {
            this.currentModel = this.models.find(m => m.name === this.currentModelName);
            localStorage.setItem(`currentModel_${this.programIndex}`, JSON.stringify(this.currentModel));
            localStorage.setItem(`currentModelName_${this.programIndex}`, this.currentModelName);
            this.$emit('updateCurrentModel', this.currentModel);
        },
        saveModel() {
            if (this.currentModel.name === this.editingModel.name) {
                this.currentModel.name = this.editingModel.name;
                this.updateCurrentModel();
            }
            if (this.editingModelIndex !== null) {
                this.models[this.editingModelIndex] = this.editingModel;
            } else {
                this.models.push(this.editingModel);
            }
            this.saveModels();
            this.closeModel();
        },
        saveModels() {
            localStorage.setItem('models', JSON.stringify(this.models));
        },
        closeModel() {
            this.showModelModal = false;
            this.editingModelIndex = null;
        }
    },
}
</script>

<style scoped>
.box-card {
    height: 400px;
}

.config-section {
    width: 30%;;
    height: 270px;
    border: 1px solid #ddd; /* 添加边框 */
    padding: 10px; /* 添加内边距 */
    margin: 10px; /* 添加外边距 */
}
.section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.custom-list {
  list-style-type: none;
  padding-left: 0;
}

.codemirror-editor {
    height: 300px;
    border: 1px solid #ddd;
}</style>